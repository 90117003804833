<template>
    <div class="container">
      <div class="py-5 text-center">
        <img src="/images/prios_logo.jpg" alt="Prios Personal">
        <h2>Prios Social Media Image Generator</h2>
      </div>

      <template v-if="!isLoading">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="jobtitle">Jobtitel</label>
              <input v-model="text" type="text" class="form-control" id="jobtitle" placeholder="Löter (m/d/w) gesucht">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="email">Bewerbung an</label>
              <input v-model="application" type="email" class="form-control" id="email" placeholder="villingen@prios-personal.de">
            </div>
          </div>

          <div class="col-md-12" v-if="!selected">
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search"></i>
                </span>
              </div>
              <input type="text" class="form-control" placeholder="Suchbegriff eingeben" v-model="searchTerm">
            </div>
              <div class="file-list" v-if="hasResults">
                <div class="card cursor-pointer" @click="selectItem(item)" v-for="(item, i) in search" :key="i">
                  <div class="card-body">
                    <div class="job-image">
                      <img :src="item" :alt="item">
                    </div>
                    <div class="text-center">
                      {{ capitalize(beforeStr(trim(item), '.')) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-results" v-else>Keine Ergebnisse gefunden</div>
          </div>

          <div class="col-md-12" v-if="selected && !manualDownload">

            <div class="d-block d-lg-flex align-items-center">
              <div class="button-row">
                <button class="btn btn-primary" @click="downloadImage" :disabled="!canSave">
                  <span v-if="!saving">
                    Download
                  </span>
                  <span v-else>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Herunterladen...
                  </span>
                </button>
                <button class="btn btn-danger ml-2" @click="selected = null"><i class="fa fa-trash"></i> Neues Bild wählen</button>
              </div>
              <div class="ml-auto">
                <a v-if="downloadComplete" href="#" @click.prevent="manualDownload = true">Klicke hier falls deine Dateien nicht automatisch heruntergeladen wurden.</a>
              </div>
            </div>




            <div class="row mt-4">
              <div class="col-12 col-md-6">
                <div class="post-wrapper" ref="post">
                  <img class="post-inset" :src="post" :alt="post">
                  <div class="post-shadow"></div>
                  <img class="position-absolute post-overlay" :src="postFrame" alt="framepost">
                  <div class="post-text" :style="postFont" v-html="completeTitle"></div>
                  <div class="post-text-application" v-if="application" :style="postFontApplicationValue">
                      <div class="application-static" :style="postFontApplicationTo">BEWERBUNG AN</div>
                      {{ application }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="story-wrapper" ref="story">
                  <img class="post-inset" :src="story" :alt="story">
                  <div class="post-shadow"></div>
                  <img class="position-absolute post-overlay" :src="storyFrame" alt="framestory">
                  <div class="story-text" v-html="completeTitle" :style="storyFont"></div>
                  <div class="story-text-application" v-if="application" :style="storyFontApplicationValue">
                      <div class="application-static" :style="storyFontApplicationTo">BEWERBUNG AN</div>
                      {{ application }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 download-area" v-show="manualDownload">

            <div class="my-2 alert alert-info">
              Falls Deine Dateien nicht automatisch heruntergeladen wurden, kannst du sie nachfolgend herunterladen.
              <button class="btn btn-primary" @click="manualDownload = false">Zurück</button>
            </div>

            <div><strong>Post: </strong></div>
            <div class="download-post" ref="downpost"></div>

            <hr class="mb-2">
            <div><strong>Story: </strong></div>
            <div class="download-story" ref="downstory"></div>
          </div>

        </div>
      </template>
      <div class="d-flex justify-content-center" v-else>
          <div class="d-flex align-items-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="mt-10 ml-2">Anwendung wird geladen</div>
          </div>
      </div>


      <footer class="my-5 pt-5 text-muted text-center text-small">
        <p class="mb-1">&copy; Wellemachen</p>
      </footer>
    </div>
</template>

<script>

import domtoimage from 'dom-to-image';

export default {

  async mounted() {
    const el = document.getElementById('body').getAttribute('data-api');

    this.jobs = (await this.$axios.get(el + '/api.php')).data;
    this.isLoading = false;

    window.onresize = () => {
      this.postWidth = this.$refs.post.clientWidth;
      this.storyWidth = this.$refs.story.clientWidth;
    };
  },

  data() {
    return {
      postWidth: null,
      storyWidth: null,
      manualDownload: false,
      isLoading: true,
      jobs: [],
      searchTerm: '',
      selected: null,
      text: '',
      application: '',
      saving: false,
      downloadComplete: false,
    }
  },

  computed: {

    postFont() {
      const scale = (70 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    postFontApplicationTo() {
      const scale = (32 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    postFontApplicationValue() {
      const scale = (36 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    storyFont() {
      const scale = (82 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    storyFontApplicationTo() {
      const scale = (36 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    storyFontApplicationValue() {
      const scale = (40 * (this.postWidth / 1080)) + 'pt' ;
      return {fontSize: scale}
    },

    postFrame() {
      if (!this.application || this.application.length < 28)
        return '/images/prios_frame_post_short.png';
      return '/images/prios_frame_post.png';
    },

    storyFrame() {
      if (!this.application || this.application.length < 28)
        return '/images/prios_frame_story_short.png';
      return '/images/prios_frame_story.png';
    },

    search() {
      if(!this.searchTerm) {
        return this.jobs.posts;
      }

      return this.jobs.posts.filter(item => item.includes(this.searchTerm));
    },

    canSave() {
      return this.text && !this.saving;
    },

    hasResults() {
      return this.search.length > 0;
    },

    post() {
      return '/images/posts/' + this.selected;
    },

    story() {
      return '/images/stories/' + this.selected;
    },

    completeTitle() {
      if(! this.text) {
        return '';
      }

      return this.text + "<br>" + "(m/d/w) gesucht";
    },

    completeApplication() {
      if(! this.application) {
        return '';
      }

      return "BEWERBUNG AN<br>" + this.application;
    },



  },

  methods: {

    init() {
      this.postWidth = this.$refs.post.clientWidth;
      this.storyWidth = this.$refs.story.clientWidth;
    },

    async selectItem(item) {
      this.selected = this.trim(item);
      await this.$nextTick();
      this.init();
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    trim(input) {
      const split = input.split('/');
      return split[split.length - 1];
    },

    beforeStr(input, char) {
      return input.substring(0, input.indexOf(char));
    },

    async downloadImage() {
      this.downloadComplete = false;
      const nodePost = this.$refs.post;
      const nodeStory = this.$refs.story;
      const scale = 1080 / nodePost.offsetWidth;
      const scaleStory = 1080 / nodeStory.offsetWidth;
      this.saving = true;

      const imageName = 'prios_post_' + this.beforeStr(this.selected, '.') + '_' + this.beforeStr(this.application, '@') + '.png';
      const storyName = 'prios_story_' + this.beforeStr(this.selected, '.') + '_' + this.beforeStr(this.application, '@') + '.png';

      await domtoimage
      .toPng(nodePost, {
          height: nodePost.offsetHeight * scale,
          width: nodePost.offsetWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            width: nodePost.offsetWidth + "px",
            height: nodePost.offsetHeight + "px"
          }
      })
      .then(dataUrl => {
          var link = document.createElement('a');
          link.download = imageName;
          link.href = dataUrl;
          link.click();

          const img = new Image();
          img.src = dataUrl;
          this.$refs.downpost.appendChild(img);

          this.saving = false;
      })
      .catch((e) => {
        console.log(e);
          alert("FEHLER!");
      });

      await domtoimage
      .toPng(nodeStory, {
          height: nodeStory.offsetHeight * scaleStory,
          width: nodeStory.offsetWidth * scaleStory,
          style: {
            transform: "scale(" + scaleStory + ")",
            transformOrigin: "top left",
            width: nodeStory.offsetWidth + "px",
            height: nodeStory.offsetHeight + "px"
          }
      })
      .then(dataUrl => {
          var link = document.createElement('a');
          link.download = storyName;
          link.href = dataUrl;
          link.click();

          const img = new Image();
          img.src = dataUrl;
          this.$refs.downstory.appendChild(img);

          this.saving = false;
      })
      .catch((e) => {
          console.log(e);
          console.log("TEST");
          alert("FEHLER!");
      });

      this.saving = false;
      this.downloadComplete = true;

    }
  }
}
</script>